

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_PAYMENT_URL } from '../../connection.data';
import { formatDate } from '../../util/date.util';

@Injectable()
export class PaymentService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_PAYMENT_URL;
    }

    markAsFinished(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/finished`, item).pipe(
            map((response)=>response.status)
        );
    }

    getPeriod(st:Date, et:Date):Observable<any[]>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.get<any>(`${this.baseUrl}/period?st=${encodeURIComponent(formatDate(st))}&et=${encodeURIComponent(formatDate(et))}`).pipe(
            map((response)=>response.rows)
        );
    }
}